import { useEffect, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Images from "../../../helpers/images";
import moment from "moment";

const LaunchModal = () => {
  const [showModal, setshowModal] = useState(false);
  const closeModal = () => setshowModal(false);

  useEffect(() => {

    var lastDate = moment('2025-02-17').startOf('day')
    var today = moment().startOf('day');
    setshowModal(today.isSameOrBefore(lastDate))
  }, [])

  return (
    <>
      <Modal
        className="dark-modal-wrapper"
        size="lg"
        centered
        show={showModal}
        onHide={closeModal}
      >
        <div className="close-btn" onClick={closeModal}>
          <img
            loading="lazy"
            src={Images.Close}
            className="img-fluid icon-close"
            alt=""
            style={{ 'filter': 'drop-shadow(1px 1px 1px #000)' }}
          />
        </div>
        <Modal.Body>
          <img
            loading="lazy"
            src={Images.bharatTex2025}
            className="img-fluid icon-close"
            alt=""
          />
        </Modal.Body>
      </Modal>
    </>
  );
};
export default LaunchModal;
